.eapermadd {
  background-color:#FFF;
  padding:10px;
  .eapermcont {
    display:flex;
    margin:10px;
    background-color:#DDD;
    padding:10px;

    .eapermlab {
      flex:1;
      margin-right:20px;
    }
    .eapermissions {
      flex:2;
    }
  }
}

.eapermread {
  display:flex;
  margin:20px 0;
  background-color:#FFF;
  padding:10px;
  flex-wrap: wrap;
  
  .eapermcard {
    flex:1 1 70px;
    width:70px;
  }
}


//THE ACTUAL EA DASHBOARD
.eacont {
  
  .eahead {
    background-color:#BBBFCF;
    padding:20px;
    font-size:18pt;
    color:#FFF;
  }
  .eainfo {
    padding:20px;
  }
  
  .eamenu {
    position:fixed;
    padding-top: 10px;
    color:#666;
    top:10px;
    right:0;
    width:140px;
    border:#EEE solid 1px;
    border-right:none;
    border-radius:10px 0 0 10px;
    background-color:#FFF;
    z-index: 99999;
    transition:right 0.5s, opacity 0.5s;

    div {
      padding:10px 20px 10px 20px;
      cursor:pointer;
    }
    
    .active {
      background-color:#DDD;
    }
  }
}