.boomSMSForm {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100vw;
  height:100vh;
  
  .boomSMSCard {
    flex-basis:80%; 
    min-width:300px;
    
    .boomSMSMobileLabel {
      margin:1% !important;
      flex:1 1 23%;
      max-width:23%;
      min-width:100px;
    }
  }
}

@media only screen and (max-width: 767px) and (min-width: 0px) {
  .boomSMSCard {
    flex-basis:90%; 
    min-width:300px;
    
    .boomSMSMobileLabel {
      flex:1 1 48%;
      max-width:48%;
      min-width:100px;
    }
  }
}
