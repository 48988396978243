@import './template.scss';
@import './customsemantics.scss';
@import './feedback.scss';
@import './promo.scss';
@import './customer.scss';
@import './comments.scss';
@import './sms.scss';
@import './eventsadmin.scss';
@import './blasts.scss';
@import './surveys.scss';
@import './prizes.scss';
@import './modal.scss';

html, body{
  width:100%;
  height:100%;
}

* {
  font-weight:400 !important;
}

#root {
  min-height:100%;
}

.paginateitem {
  margin-right:10px;
  cursor:pointer;
}

.boomTags {
  margin:5px !important;
}

.boomFilter {
  .boomFilterInput {
    max-width:50%;
    
    div {
      width:100% !important;
    }
  }
  .boomFilterCheckbox {
    margin:10px;
  }
}

.loadScreen {
  height:100vh;
  width:100vw;
  background-color:rgba(0,0,0,0);
  position:fixed;
  left:0;
  top:0;
  display:flex;
  align-items: center;
  justify-content: center;
  z-index:99999;
  
  .loadIcon{
    background-color:rgba(100,100,100,0.7);
    padding:30px 50px;
    display:flex;
    flex-direction: column;
    border-radius:10px;
    align-items: center;
    justify-content: center;
  }
}

.boomCont {
  min-height: 100vh !important;
  width:10% !important;
  position:fixed;
  left:0;
  top:0;
  z-index:999;
}

.boomCont, .boomContMobile {
  background-color:#999 !important;
  .signOut {
    background-color:#F99 !important;
    color:#FFF !important;
  }
}


.boomlogin {
  position:fixed;
  left:0;
  top:0;
  right:0;
  bottom:0;
  margin:auto;
  min-width: 30vw;
  min-height: 30vh;
  max-width: 80vw;
  max-height: 80vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.boomburger {
  position:fixed;
  left:0;
  top:10px;
  display:none !important;
  z-index:999;
}


.preview {
  background-size:cover;
  background-position: center;
  width:100%;
  height:150px;
}

.boomOpts {
  display:block !important;
  margin:10px 0 !important;
}

.ctrlSeg {
  background-color:#f6f9ff !important;
  max-width:97% !important;
  min-height:70vh;
}

.boomDropdown {
  color:#999 !important;
}

.fileinput {
    position: absolute;
    filter: alpha(opacity=0);
    opacity: 0;
}

.fileinputlabel {
    cursor:pointer;
}

.msghead {
  font-size:14px;
  color:#666;
  margin-left:10px;
}

.msgdate {
  color:#aaa;
  font-size:12px;
}

.eventsDesc {
  max-height:200px;
  overflow-y: auto;
}

/*=============MEDIA QUERY=======================*/
@media only screen and (max-width: 767px) and (min-width: 0px) {
  
  .boomCont {
    display:none !important;
  }
  
  .boomContMobile {
    min-height: 100vh !important;
    width:25% !important;
    position:fixed;
    left:0;
    top:0;
    z-index:1000;
  }
  
  .boomburger {
    display:block !important;
  }
  
  .ctrlSeg {
    max-width:100% !important;
  }
}
