.promoCard {
  word-wrap:break-word;
}

.qrcont {
  position:fixed;
  left:0;
  top:0;
  width:100vw;
  height:100vh;
  background-color:#FFFFFF;
  display:flex;
  justify-content: center;
  align-items: center;
  z-index:999999;
  flex-direction: column;
  overflow-y: auto;
  
  .qrcontrols{
    display:flex;
    margin:10px;
    position:absolute;
    top:0;
    right:0;
  }
  
  .qrbox {
    display:flex;
    flex-direction: row;
    font-weight: 300;
    margin:10px;
    
    .qrinfo {
      margin-left:10px;
      display:flex;
      flex-direction: column;
      position:relative;
      
      .qrprompt {
        flex:1;
        color:#999;
        font-size:100%;
        display:flex;
        justify-content: center;
        align-items: center;
      }
      .qrhash {
        flex:3;
        font-size:200%;
        background-color:#AAA;
        padding:10px;
        display:flex;
        justify-content: center;
        align-items: center;
        color:#FFF;
        font-weight: 400;
      }
      .qrmobile {
        flex:2;
        display:flex;
        font-size:150%;
        
        div:first-child{
          flex:1;
          padding:10px;
          color:#999;
          background-color:#EFEFEF;
          display:flex;
          justify-content: center;
          align-items: center;
        }
        div:last-child {
          flex:4;
          padding:10px;
          background-color:#666;
          color:#FFF;
          display:flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.promo {
  display:inline-block;
  background-size:cover;
  background-position: center;
  width:100%;
  height:300px;
  max-height:300px;
}

.promopreview {
  display:flex;
  width:90%;
  height:50px;
  opacity:0.9;
  margin-bottom:10px;
  border-radius:0 0 5px 5px;
}

.promoLastSMSCont {
  margin:10px 0;
  .promoLastSMS {
    display:flex;
    
    div {
      padding:10px;
    }
    
    div:nth-child(1){
      background-color:#FFFFFF;
      font-size:14px;
    }
    
    div:nth-child(2) {
      background-color:#AAAAAA;
      color:#FFFFFF;
      font-size:14px;
      text-transform: uppercase;
    }
    
    div:nth-child(3) {
      flex:1;
      background-color:#666666;
      color:#FFFFFF;
      font-size:14px;
    }
  }
  .promoLastSMSMsg {
    padding:10px;
    background-color:#666666;
    color:#FFFFFF;
  }
}