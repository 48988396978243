.commentsMsg {
  position:relative;
  display:flex;
  background-color:#FFF;
  padding:10px;
  max-width:100% !important;
  flex-direction:column;
  border-radius: 10px;
}

.commentIcon {
  align-self: flex-end;
  margin-top:10px;
}