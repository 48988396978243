.boomModalCont {
  position:fixed;
  background-color:rgba(0,0,0,0.5);
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index: 99999;
  display:flex;
  justify-content: center;
  align-items: center;
  
  .boomModalInfo {
    width:90%;
    height:90%;
    display:flex;
    justify-content: center;
    align-items: center;
    
    .boomModalMain {
      max-width:100%;
      max-height:100%;
      position:relative;
      
      .modalCont {  
        background-color:#FFFFFF;
        padding:20px;
        max-height:80vh !important;
        max-width:90vw !important;
        overflow-y: auto;
      }
      
      .boomModalClose {
        position:absolute;
        top:-15px;
        right:-15px;
        background-color:#FFFFFF;
        z-index:99999;
      }
    }
  }
}