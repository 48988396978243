.templateBox {
  flex:1 0 31%;
  max-width: 31%;
  border-radius: 10px;
  background-color:#FFF;
  padding:10px;
  margin:1%;
  button {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 767px) and (min-width: 0px) {
  .templateBox{
    flex:1 1 48%;
    max-width: 48%;
  }
}

@media only screen and (max-width: 450px) and (min-width: 0px) {
  .templateBox{
    flex:1 1 100%;
    max-width: 100%;
  }
}