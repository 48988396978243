.ui.inverted.menu .active.item, .ui.inverted.menu .item:hover  {
  background-color:#555 !important;
}

.ui.card {
  background-color:#FCFCFC !important;
  border-color: #DDDDDD !important;
}

@media only screen and (max-width: 767px) and (min-width: 0px) {
  .ui.grid > .row > .column {
    padding-left:0px !important;
    padding-right:0px !important;
  }
}

.ui.dropdown:not(.button) > .default.text {
  color:#CCCCCC !important;
}
