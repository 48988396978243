.feedbackCont {
  display:flex;
  flex-direction:row;
  
  .feedbackNums {
    flex:1;
    max-height:700px;
    overflow-y: auto;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    word-wrap:break-word;
    
    .nums {
      
      .numsContact{
        background-color:#FAFAFA;
        padding:10px 10px 0 10px;
        color:#CDCDCD;
      }
      
      .numsLastMsg{
        padding:0 10px 10px 10px;
        font-size:12px;
        background-color:#FAFAFA;
        color:#CDCDCD;
        
      }
      
      .active {
        background-color: #2185D0 !important;
        color: #FFFFFF !important;
      }
    }
  }
  
  .feedbackMsgsCont {
    flex:3;
    max-width:65%;
    word-wrap:break-word;
    
    .feedbackMsgs {
      max-height:400px;
      overflow-y: auto;
      display:flex;
      flex-direction: column;
      padding:0 10px;
      
      .loadmore {
        display:block;
        min-height:40px;
      }
      
      .msgsIn {
        align-self: flex-end;
        background-color:#F0F0F0;
      }
      
      .msgsInTags {
        align-self: flex-end;
        background-color:#F0C500;
        color:#FFFFFF;
      }

      .msgsOut {
        align-self: flex-start;
        background-color:#2185D0;
        color:#FFFFFF;
      }

      .msgs {
        margin:5px 0;
        padding:10px;
        border-radius:10px;
        max-width: 90%;
      }

      .msgs:first-child {
        margin-top:0px; 
      }

      .dateDisp {
        font-size:10px;
      }
    }
    
    
    .feedbackReply {
      align-self: flex-end;
      margin-bottom: 10px;
      padding:10px;
      background-color:#FFFFFF;
      
      .feedbackGroups{
        margin-top:10px;
      }
    }
  }
}

