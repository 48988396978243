.blastsPrepare {
  width:100%;
  
  .blastsAllMobiles {
    width:100%;
    max-height:300px;
    overflow-y: auto;
    display:flex;
    flex-wrap: wrap;
    
    .blastsLabel {
      width:23%;
      display:flex;
      margin:5px;
      
      .blastsLabelClose{
        width:10%;
        margin-right:10px;
      }
    }
    
    
  }
  
}

.blastsMsg {
  margin:10px 0;
  
  .blastsMsgHead {
    display:flex !important;
    
    .dateDisp {
      font-size:12px; 
      color:#ddd; 
      font-weight:300;
    }

    .mobCont {
      
      .mobLabel {
        margin:5px;
        
        .mobLabelText {
          background-color:#EEEEEE;
          border-bottom:#DDDDDD solid 1px;
          font-size:14px;
          padding:10px;
          color:#333333;
          
          span {
            margin-left:5px;
            color:#AAAAAA;
          }
          
          button {
            margin-left:5px;
          }
        }
        
        .mobLabelTextMore{
          color:#999999;
          background-color:#f6f9ff;
          font-size:12px;
          font-weight: 800;
          cursor:pointer;
        }
      }
    }
  }
  
  .blastsMsgHead > div {
    padding:5px;
  }
  
  
}

.blastsMsg:last-child{
  margin-bottom: 100px;
}

